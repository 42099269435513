import Themes from '@/themes';
import axios, { AxiosRequestConfig } from 'axios';

interface IAccessTokenResponse {
  accessToken: string;
  expiresIn: number;
  tokenType: string;
}

export class RideitBackend {
  static host = document.location.href.includes('.ngrok.io')
    ? ''
    : (process.env.VUE_APP_API_HOST || '');
  static baseUrl = RideitBackend.host + '/';
}

const getFromCache = async <T>(url: string) => {
  const cache = await caches.open('api');
  const res = await cache.match(url);
  if (!res || !res.ok) {
    throw new Error('GET ' + url + ' not cached');
  }
  return await res.json() as T;
};

const rideItTenantHeaderName = "X-Rideit-Tenant";

const decorateRequestConfig = (config?: AxiosRequestConfig) => {
  if (!config){
    config = {
    }
  }
  if (!config.headers){
    config.headers = {};
  }
  if (!config.headers[rideItTenantHeaderName]){
    const tenantFromUrl = Themes.MatchTenantFromUrl();
    if (tenantFromUrl && tenantFromUrl !== 'default'){
      config.headers[rideItTenantHeaderName] = tenantFromUrl;
    } else {
      // No specific tenant from current Url found. Don't set the header, 
      // so that backend can still use it's own config override.
    }
  }
  return config;
};

const restGet = async <T>(url: string, config?: AxiosRequestConfig) => {
  const response = await axios.get<T>(RideitBackend.baseUrl + url, decorateRequestConfig(config));
  return response.data;
};

const restPost = async <T>(url: string, data: any, config?: AxiosRequestConfig) => {
  const response = await axios.post<T>(RideitBackend.baseUrl + url, data, decorateRequestConfig(config));
  return response.data;
};

const restPut = async <T>(url: string, data: any, config?: AxiosRequestConfig) => {
  const response = await axios.put<T>(RideitBackend.baseUrl + url, data, decorateRequestConfig(config));
  return response.data;
};

const restDelete = async (url: string, config?: AxiosRequestConfig) => {
  const response = await axios.delete(RideitBackend.baseUrl + url, decorateRequestConfig(config));
  return response;
};

export default {
    auth: {
      getUserData: () => {
        return restGet<IUser>('api/account');
      },
      updateLogin: (username: string, password: string) => {
        return restPost('api/account/edit', { userName: username, password}, {
          withCredentials: true,
        });
      },
      changePassword: (username: string, password: string, currentPassword: string) => {
        return restPost('api/account/changePassword', { userName: username, password, currentPassword }, {
          withCredentials: true,
        });
      },
      getAccessToken: () => {
        if (process.env.NODE_ENV === 'development') {
          return restGet<IAccessTokenResponse>('api/auth/access_token', {
            withCredentials: true,
          });
        }
        return restGet<IAccessTokenResponse>('api/auth/access_token');
      },
      registerTravelCard: (cardNumber: string, name: string ) => {
        return restPost<IUserInvitation>(`api/account/registercard`, { cardNumber, name });
      },
    },
    passenger: {
      get: () => restGet<IPassenger[]>('api/passenger'),
      getTrips: (id: string) => restGet<ITrip[]>(`api/passenger/${id}/trips`),
      claim: (token: string) => restPost<IPassengerToken>('api/passenger', { token }),
      put: (passenger: IPassenger) => restPut<IPassenger>(`api/passenger/${passenger.id}`, passenger),
      getPassengerPlaces: (id: string) => restGet<IBookingAddressInfo>(`api/passenger/${id}/places`),
    },
    trips: {
      cancel: (cancellation: ITripCancellation) =>  restPost<ITrip[]>('api/trip/cancel', cancellation),
      review: (review: ITripReview) => restPost<ITripReview>('api/trip/review', review),
      tracked: (link: string) => restGet<ITrip>(`api/track/${link}`),
      withStatus: (request: ITripStatusRequest) => restPost<ITrip>(`api/trip/status`, request),
    },
    message: {
      get: (page?: number) => {
        let url = 'api/message';
        if (page) {
          url += '?page=' + page;
        }
        return restGet<IPagedMessages>(url);
      },
      send: (passengerId: string, content: string) => restPost<IMessage>('api/message', { passengerId, content}),
      markSeen: (message: IMessage) => restPut<IMessage>(`api/message/${message.id}/seen`, message),
      markConfirmed: (message: IMessage) => restPut<IMessage>(`api/message/${message.id}/confirm`, message),
    },
    invitation: {
      get: (code: string) => {
        return restGet<IUserInvitation>(`api/invitation/${code}`);
      },
      accept: (invite: IUserInvitation) => {
        return restPost<IPassengerToken[]>('api/invitation', invite);
      },
    },
    quotas: {
      get: (passengerId: string, date: string) => restGet<IBookingQuota[]>(`api/passenger/${passengerId}/quotas?date=${date}`),
      
      getWithPeriods: (passengerId: string) => restGet<IBookingQuotaWithPeriods[]>(`api/passenger/${passengerId}/transportationPermissions`),
    },
    booking: {
      getEstimates: (booking: IBooking) => restPost<IBookingEstimate[]>('api/booking/estimate', booking),
      postBooking: (booking: IBooking) => restPost<IBooking>('api/booking', booking),
      getRoute: (destinations: IDestination[]) => restPost<IComparableRoute>('api/booking/route', destinations),
    },
    nominatim: {
      searchAddress: async<INominatimSearchResult>(search: string) => {
        const nominatimServer = 'https://nominatim.movit.fi';
        const geocodingUrl = nominatimServer + '/search?format=json&addressdetails=1&countrycodes=fi&limit=30&q=';
        const encodedAddress = encodeURIComponent(search);
        try {
          const response = await axios.get(geocodingUrl + encodedAddress);
          return response.data;
        } catch (error) {
          return [];
        }
      },
    },
    google: {
      searchAddress: async<IGoogleGeocodeSearchResults>(search: string) => {
        const key = process.env.VUE_APP_GOOGLE_KEY;
        const encodedAddress = encodeURIComponent(search);
        // tslint:disable-next-line:max-line-length
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${key}&language=fi&region=fi`;

        try {
          const response = JSON.parse(await makeRequest('GET', url));
          const results = response.results
            .filter(filterGoogleResults)
            .map(convertGoogleResultToDestination);
          return results;
        } catch (error) {
          return [];
        }
      },
    },
};

function makeRequest(method: string, url: string): Promise<XMLHttpRequest['response']> {
  return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(method, url);
      xhr.onload = function() {
          if (this.status >= 200 && this.status < 300) {
              resolve(xhr.response);
          } else {
              reject({
                  status: this.status,
                  statusText: xhr.statusText,
              });
          }
      };
      xhr.onerror = function() {
          reject({
              status: this.status,
              statusText: xhr.statusText,
          });
      };
      xhr.send();
  });
}

function filterGoogleResults(result: IGoogleSearchResult) {
  return true;
}

function convertGoogleResultToDestination(result: IGoogleSearchResult) {
  const destination: IDestination = {
    id: result.place_id,
    alias: '',
    display: result.formatted_address,
    address: result.formatted_address.split(',')[0],
    postalcode: result.address_components.filter(c => c.types.indexOf('postal_code') >= 0)[0].long_name,
    city: result.address_components.filter(c => c.types.indexOf('locality') >= 0)[0].long_name,
    latitude: result.geometry.location.lat,
    longitude: result.geometry.location.lng,
    isHome: false,
    isPickup: false,
    isViaPoint: false,
    isMain: false,
  };
  return destination;
}

