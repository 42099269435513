import IGooglePlaceAddressComponents from './IGooglePlaceAddressComponents';
import IGooglePlaceCachedPrediction from './IGooglePlaceCachedPrediction';

class PlacePickerDestination implements IDestination {

  static fromGooglePlaceResult(result: google.maps.places.PlaceResult): PlacePickerDestination {
    const place = new PlacePickerDestination();
    place.source = DestinationSource.GooglePlaceResult;

    const addressComponents: IGooglePlaceAddressComponents = {};

    const addressComponentTypes: any = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'long_name',
      postal_code: 'short_name',
    };

    result.address_components?.forEach((ac: any) => {
      const addressType = ac.types[0];
      if (addressComponentTypes[addressType]) {
        const val = ac[addressComponentTypes[addressType]];
        (addressComponents as any)[addressType] = val;
      }
    });

    const streetAddress = [addressComponents.route, addressComponents.street_number]
      .filter(x => x)
      .join(' ');

    const postalCode = addressComponents.postal_code;

    let locality = addressComponents.locality ? addressComponents.locality : result.vicinity;
    if (result.name === locality) {
      locality = '';
    }

    const combinedAddress = [result.name, locality]
      .filter(x => x)
      .join(', ');

    const address = combinedAddress ? combinedAddress : result.formatted_address + '';

    place.alias = address;
    place.address = streetAddress ? streetAddress : result.formatted_address + '';
    place.streetnumber = addressComponents.street_number ? addressComponents.street_number : '';
    place.city = locality + '';
    place.postalcode = postalCode ? postalCode : '-';
    place.latitude = typeof(result.geometry?.location.lat) === 'number'
    ? result.geometry.location.lat as unknown as number
    : result.geometry?.location.lat() as unknown as number;
    place.longitude = typeof(result.geometry?.location.lng) === 'number'
    ? result.geometry.location.lng as unknown as number
    : result.geometry?.location.lng() as unknown as number;

    return place;
  }

  static fromGooglePlacePrediction(result: IGooglePlaceCachedPrediction): PlacePickerDestination {
    const place = new PlacePickerDestination();
    place.source = DestinationSource.GooglePlacePrediction;
    place.alias = result.prediction.description;
    place.prediction = result.prediction;
    return place;
  }

  static fromRideitDestination(destination: IDestination): PlacePickerDestination {
    const place = new PlacePickerDestination();
    Object.keys(destination).forEach(key => {
      (place as any)[key] = (destination as any)[key];
    });
    place.source = DestinationSource.Rideit;
    const streetAddress = destination.address.split(',')[0];
    place.alias = place.alias ? place.alias : [streetAddress, destination.city].filter(x => x).join(', ');
    return place;
  }

  id: string = '';
  alias: string = '';
  display: string = '';
  address: string = '';
  streetnumber?: string;
  city: string = '';
  postalcode: string = '';
  latitude: number = 0;
  longitude: number = 0;
  isHome: boolean = false;
  isViaPoint: boolean = false;
  isPickup: boolean = false;
  isMain: boolean = false;
  prediction?: google.maps.places.AutocompletePrediction;
  source?: DestinationSource;

}

export enum DestinationSource {
  Unknown,
  Rideit,
  GooglePlaceResult,
  GooglePlacePrediction,
}

export default PlacePickerDestination;
